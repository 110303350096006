<template>
  <div class="bg-white mt-12 px-6 py-6 mb-6 big-shadow">
    <trac-loading v-if="loading" />
    <template>
      <div class="flex justify-between">
        <trac-custom-header>
          <template slot="leading"
            ><span class="font-medium">Pricing</span></template
          >
          <template slot="trailing">
            <span class="font-medium"> And</span> Inventory</template
          >
        </trac-custom-header>
        <button
          v-if="modifier.has_variant || modifier.options.length"
          @click="
            () => {
              $emit('update-modifier', {
                has_variant: true,
              });
              $emit('toggle-add-options');
            }
          "
          class="text-xs font-semibold text-blue-800 flex items-center gap-x-1 focus:outline-none add-option-btn"
        >
          <img
            src="@/assets/svg/tiny-plus-bold.svg"
            class="w-5 h-5"
            alt="tiny-plus-icon"
          />
          <span class="font-extrabold"> Add Option </span>
        </button>
      </div>

      <div class="md:flex flex-row gap-x-10 mt-8 mb-5">
        <div class="flex-column md:w-4/12">
          <trac-input
            v-model.number="modifier.sale_price"
            type="number"
            placeholder="Selling Price"
          />
        </div>
        <div class="flex-column md:w-4/12">
          <trac-input
            v-model.number="modifier.cost_price"
            type="number"
            placeholder="Cost Price"
          />
        </div>
        <!-- <div class="flex-column md:w-4/12">
          <trac-input
            v-model.number="modifier.stock_quantity"
            type="number"
            placeholder="Stock Quantity"
          />
        </div> -->
      </div>

      <h2 class="text-lg text-primaryBlue font-medium">SKU Details</h2>
      <div class="md:flex flex-row gap-x-10 mt-8">
        <div class="flex-column md:w-4/12">
          <trac-input
            :value="modifier.barcode"
            @input="
              $emit('update-modifier', {
                barcode: $event,
              })
            "
            placeholder="SKU / Barcode"
          />
        </div>

        <div class="md:w-2/12">
          <trac-button
            @button-clicked="generateBarcode"
            class="uppercase text-xs w-full"
            variant="outline"
          >
            Generate SKU / Barcode
          </trac-button>
        </div>
      </div>

      <div
        v-if="!modifier.id ? !modifier.has_variant : true"
        class="mt-8 md:mr-32 pt-8 border-t font-semibold text-sm"
      >
        <!-- :disabled="updateProductFlag" -->
        <trac-toggle
          v-model="modifier.manage_stock"
          :active="modifier.manage_stock"
          :disabled="modifier.id"
          @newval="updateManageStock"
          >Would you like to manage stock for this item</trac-toggle
        >
      </div>
      <ManageStock
        v-if="modifier.manage_stock"
        :stores="stores"
        :product="modifier"
        @set-store-stock="$emit('update-modifier', { store_stock: $event })"
      />
    </template>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import ManageStock from "../../products/ManageStock.vue";

export default {
  name: "Variation",
  components: {
    ManageStock,
  },
  emits: ["update-modifier", "toggle-add-options"],
  props: {
    modifier: {
      type: Object,
      default: () => {},
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generateBarcode() {
      this.loading = true;
      try {
        await this.$store.dispatch("GENERATE_BARCODE");
        const res = this.$store.getters["GET_GENERATED_BARCODE"];
        if (res.status) {
          this.$emit("update-modifier", {
            barcode: res.data,
          });
          this.$store.commit("POPULATE_GENERATED_BARCODE", null);
        }
      } catch (error) {
        eventBus.$emit("trac-alert", { message: res.message });
      } finally {
        this.loading = false;
      }
    },
    updateManageStock(value) {
      this.$emit("update-modifier", { manage_stock: !value });
    },
  },
};
</script>

<style lang="scss" scoped></style>
