var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('trac-loading'):_vm._e(),_c('div',{staticClass:"bg-white mt-12 px-6 py-6 mb-6 big-shadow"},[_c('trac-back-button',{staticClass:"capitalize"},[_vm._v("Back")]),_c('h3',{staticClass:"font-bold mt-4 text-2xl"},[_vm._v(_vm._s(_vm.pageTitle))])],1),_c('div',{staticClass:"bg-white mt-12 px-6 py-6 mb-6 big-shadow"},[_c('trac-custom-header',[_c('template',{slot:"leading"},[_vm._v("Modifier")]),_c('template',{slot:"trailing"},[_vm._v(" Details")])],2),_c('div',{staticClass:"grid grid-cols-12 gap-5 pb-6"},[_c('div',{staticClass:"md:col-span-3"},[_c('trac-image-upload',{staticClass:"md:col-span-3 pr-3",attrs:{"id":"modifier-image","image":_vm.modifier.image},on:{"set-file-image":function($event){_vm.imageToUpload = $event}}})],1),_c('div',{staticClass:"md:col-span-9 w-full justify-between mt-10 md:mt-0 border-l pl-6 border-gray-200"},[_c('div',{staticClass:"w-full grid grid-cols-12 gap-x-12 gap-y-8"},[_c('trac-validation-provider',{staticClass:"col-span-6",attrs:{"name":"product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('trac-input',{attrs:{"placeholder":"Modifier Name"},model:{value:(_vm.modifier.title),callback:function ($$v) {_vm.$set(_vm.modifier, "title", $$v)},expression:"modifier.title"}}),(errors[0])?_c('trac-input-error',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"flex-column w-full mt-10 md:mr-24"},[_c('trac-textarea',{attrs:{"placeholder":"Description"},model:{value:(_vm.modifier.description),callback:function ($$v) {_vm.$set(_vm.modifier, "description", $$v)},expression:"modifier.description"}})],1),_c('div',{staticClass:"mt-4 mb-12 font-semibold text-sm"},[_c('trac-toggle',{attrs:{"active":_vm.modifier.has_variant},on:{"newval":(e) => {
                !e && (_vm.showAddOption = !_vm.showAddOption);
                _vm.modifier.has_variant = !e;
              }},model:{value:(_vm.modifier.has_variant),callback:function ($$v) {_vm.$set(_vm.modifier, "has_variant", $$v)},expression:"modifier.has_variant"}},[_vm._v(" Does this modifier have variations ")])],1)])]),(_vm.modifier.options.length)?_c('options-table',{attrs:{"options":_vm.modifier.options},on:{"set-options":function($event){_vm.modifier.options = $event}}}):_vm._e(),(_vm.modifier.options.length && _vm.modifier.has_variant)?_c('ProductVariation',{attrs:{"product":_vm.modifier},on:{"toggle-add-options":() => {
          _vm.showAddOption = true;
          _vm.toggleAddOptions = !_vm.toggleAddOptions;
        },"update-variations":function($event){_vm.modifier.variations = [...$event]}}}):_vm._e()],1),(!_vm.modifier.has_variant)?_c('Pricing',{attrs:{"stores":_vm.stores,"modifier":_vm.modifier},on:{"toggle-add-options":() => {
        _vm.showAddOption = true;
        _vm.toggleAddOptions = !_vm.toggleAddOptions;
      },"update-modifier":function($event){_vm.modifier = {
        ..._vm.modifier,
        ...$event,
      }}}}):_vm._e(),(_vm.modifier.has_variant && _vm.showAddOption)?_c('AddOptions',{attrs:{"option-type":"modifier","product":_vm.modifier,"toggle-add-options":_vm.toggleAddOptions},on:{"add-option":_vm.addOption,"close":() => {
        _vm.showAddOption = false;
        _vm.modifier = {
          ..._vm.modifier,
          has_variant: false,
        };
      }}}):_vm._e(),_c('div',{staticClass:"flex justify-end"},[_c('trac-button',{staticClass:"uppercase",attrs:{"disabled":!_vm.modifier.title},on:{"button-clicked":_vm.createModifier}},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }