<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="bg-white mt-12 px-6 py-6 mb-6 big-shadow">
      <trac-back-button class="capitalize">Back</trac-back-button>
      <h3 class="font-bold mt-4 text-2xl">{{ pageTitle }}</h3>
    </div>

    <div class="bg-white mt-12 px-6 py-6 mb-6 big-shadow">
      <trac-custom-header>
        <template slot="leading">Modifier</template>
        <template slot="trailing"> Details</template>
      </trac-custom-header>

      <div class="grid grid-cols-12 gap-5 pb-6">
        <div class="md:col-span-3">
          <trac-image-upload
            class="md:col-span-3 pr-3"
            id="modifier-image"
            :image="modifier.image"
            @set-file-image="imageToUpload = $event"
          />
        </div>
        <div
          class="md:col-span-9 w-full justify-between mt-10 md:mt-0 border-l pl-6 border-gray-200"
        >
          <div class="w-full grid grid-cols-12 gap-x-12 gap-y-8">
            <trac-validation-provider
              class="col-span-6"
              name="product name"
              rules="required"
              v-slot="{ errors }"
            >
              <trac-input
                placeholder="Modifier Name"
                v-model="modifier.title"
              />
              <trac-input-error v-if="errors[0]">
                {{ errors[0] }}
              </trac-input-error>
            </trac-validation-provider>
            <!-- <trac-validation-provider
              class="col-span-6 relative"
              name="modifier.category"
              rules="required"
            >
              <trac-dropdown-exteneded
                neededProperty="name"
                :options="categories"
                selector="Select Category (Optional)"
                @optionSelected="handleSelectCategory($event, 'category')"
              ></trac-dropdown-exteneded>
            </trac-validation-provider> -->
            <!-- <div class="col-span-6 w-full">
              <trac-validation-provider
                class="col-span-6 relative"
                name="subcategory"
                rules="required"
              >
                <trac-dropdown-exteneded
                  neededProperty="name"
                  :disabled="!modifier.category"
                  :options="subCategories"
                  selector="Select Subcategory (Optional)"
                  @optionSelected="handleSelectCategory($event, 'subCategory')"
                ></trac-dropdown-exteneded>
              </trac-validation-provider>
            </div> -->
          </div>
          <div class="flex-column w-full mt-10 md:mr-24">
            <trac-textarea
              v-model="modifier.description"
              placeholder="Description"
            ></trac-textarea>
          </div>
          <div class="mt-4 mb-12 font-semibold text-sm">
            <trac-toggle
              @newval="
                (e) => {
                  !e && (showAddOption = !showAddOption);
                  modifier.has_variant = !e;
                }
              "
              :active="modifier.has_variant"
              v-model="modifier.has_variant"
            >
              Does this modifier have variations
            </trac-toggle>
          </div>
        </div>
      </div>

      <options-table
        v-if="modifier.options.length"
        :options="modifier.options"
        @set-options="modifier.options = $event"
      />

      <ProductVariation
        v-if="modifier.options.length && modifier.has_variant"
        :product="modifier"
        @toggle-add-options="
          () => {
            showAddOption = true;
            toggleAddOptions = !toggleAddOptions;
          }
        "
        @update-variations="modifier.variations = [...$event]"
      />
    </div>

    <Pricing
      v-if="!modifier.has_variant"
      :stores="stores"
      :modifier="modifier"
      @toggle-add-options="
        () => {
          showAddOption = true;
          toggleAddOptions = !toggleAddOptions;
        }
      "
      @update-modifier="
        modifier = {
          ...modifier,
          ...$event,
        }
      "
    />

    <AddOptions
      v-if="modifier.has_variant && showAddOption"
      option-type="modifier"
      :product="modifier"
      :toggle-add-options="toggleAddOptions"
      @add-option="addOption"
      @close="
        () => {
          showAddOption = false;
          modifier = {
            ...modifier,
            has_variant: false,
          };
        }
      "
    />

    <div class="flex justify-end">
      <trac-button
        @button-clicked="createModifier"
        class="uppercase"
        :disabled="!modifier.title"
      >
        {{ pageTitle }}
      </trac-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Pricing from "./Pricing.vue";
import AddOptions from "../../products/Options/AddOptions.vue";
import ProductVariation from "../../products/ProductVariation.vue";
import OptionsTable from "../../products/Options/OptionsTable.vue";
import { convertKeysToSnakeCase } from "@/utils";

export default {
  name: "CreateModifier",
  components: { Pricing, AddOptions, ProductVariation, OptionsTable },
  data() {
    return {
      modifier: {
        title: "",
        image: "",
        is_modifier: true,
        has_variant: false,
        sale_price: 0,
        cost_price: 0,
        options: [],
        type: "modifier",
        variations: [],
      },
      showAddOption: false,
      imageToUpload: {},
      categories: [],
      loading: false,
      toggleAddOptions: false,
      stores: [],
    };
  },
  created() {
    this.fetchAllCategories();
    this.fetchAllStores();
    if (this.$route.query?.mode === "edit") {
      this.handleFetchModifier(this.$route.query.id);
    }
  },
  computed: {
    ...mapGetters(["GET_PRODUCT_CATEGORIES"]),
    pageTitle() {
      return `${
        this.$route.query?.mode === "edit" ? "Edit" : "Create New"
      } Modifier`;
    },
    subCategories() {
      const item = this.categories.find(
        (cat) => cat.id === this.modifier.category
      );
      if (item) {
        return item.sub_categories;
      }

      return [];
    },
  },
  methods: {
    ...mapActions([
      "FETCH_PRODUCTS_CATEGORIES",
      "CREATE_MODIFIERS",
      "FETCH_PRODUCT",
      "EDIT_PRODUCT_V2",
    ]),
    handleSelectCategory(category, key) {
      this.modifier[key] = category.id;
    },
    async uploadProductImage() {
      this.loading = true;
      await this.$store.dispatch("UPLOAD_FILE", this.imageToUpload);
      const res = this.$store.getters["GET_UPLOAD_RES"];

      this.$store.commit("SETUP_FILE_UPLOADED", null);

      if (res.status) {
        this.modifier.image = res.data;
        return true;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.loading = false;
    },
    formatOptions(variations) {
      const optionsObj = variations?.length
        ? variations
            ?.flatMap((v) => v.options)
            ?.reduce((acc, curr) => {
              return {
                [curr.id]: {
                  key: curr.key,
                  values: Array.from(
                    new Set([...(acc[curr.id]?.values ?? []), curr.value])
                  ),
                },
              };
            }, {})
        : [];
      const options = Object.entries(optionsObj).map(
        ([id, { key, values }]) => ({
          id,
          key,
          values,
        })
      );
      return options;
    },
    async handleFetchModifier(id) {
      try {
        const res = await this.FETCH_PRODUCT(id);
        if (res.status) {
          this.modifier = {
            ...this.modifier,
            ...convertKeysToSnakeCase(res.data),
            cost_price: res.data?.variations?.length ? 0 : res.data?.costPrice,
            sale_price: res.data?.variations?.length ? 0 : res.data?.salePrice,
          };
          const options = this.formatOptions(this.modifier?.variations ?? []);
          this.modifier.options = options;
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
    async fetchAllCategories() {
      try {
        const res = await this.FETCH_PRODUCTS_CATEGORIES();
        if (res.status) {
          this.categories = [...this.GET_PRODUCT_CATEGORIES];
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      }
    },
    addOption(option) {
      const optionParentFound = this.modifier.options?.find(
        (op) => op.id === option.id
      );
      if (optionParentFound) {
        const optionParentFoundIndex = this.modifier.options?.findIndex(
          (op) => op.id === option.id
        );
        this.modifier.options.splice(optionParentFoundIndex, 1, {
          ...option,
          values: option.values,
        });
        return;
      }
      this.modifier.options = [...this.modifier.options, { ...option }];
      this.showAddOption = false;
    },
    async createModifier() {
      const editMode = this.$route.query?.mode === "edit";

      const dispatch = editMode ? "EDIT_PRODUCT_V2" : "CREATE_MODIFIERS";

      this.loading = true;
      if (Object.keys(this.imageToUpload)?.length)
        await this.uploadProductImage();

      const payload = {
        ...this.modifier,
        options: undefined,
        sale_price: this.modifier.sale_price || 1,
        cost_price: editMode
          ? this.modifier.cost_price
          : this.modifier.cost_price || 1,
        variations: this.modifier.has_variant
          ? this.modifier.variations.map((v) => ({
              ...v,
              store_stock: v?.store_stock?.length
                ? v?.store_stock
                    ?.map((store) => ({
                      ...store,
                      store_id: store?.store_i_d || store?.store_id,
                      re_order: store?.re_order_level || store?.re_order,
                    }))
                    ?.filter((store) => !!store?.stock_quantity)
                : [],
            }))
          : null,
        stock_quantity: this.modifier.has_variant
          ? (this.modifier.variations ?? []).reduce(
              (acc, curr) => acc + (curr.stock_quantity ?? 0),
              0
            )
          : this.modifier.stock_quantity,
        store_stock: !this.modifier.has_variant
          ? this.modifier.store_stock
          : undefined,
      };

      try {
        const res = await this[dispatch](payload);
        if (res.status) {
          this.$router.push({ name: "modifiers" });
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "modifier.has_variant"(val) {
      if (!val) {
        this.modifier = {
          ...this.modifier,
          options: [],
        };
      }
    },
    "modifier.options"(val) {
      if (!val?.length && !this.showAddOption) {
        this.modifier.has_variant = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
