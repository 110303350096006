var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white mt-12 px-6 py-6 mb-6 big-shadow"},[(_vm.loading)?_c('trac-loading'):_vm._e(),[_c('div',{staticClass:"flex justify-between"},[_c('trac-custom-header',[_c('template',{slot:"leading"},[_c('span',{staticClass:"font-medium"},[_vm._v("Pricing")])]),_c('template',{slot:"trailing"},[_c('span',{staticClass:"font-medium"},[_vm._v(" And")]),_vm._v(" Inventory")])],2),(_vm.modifier.has_variant || _vm.modifier.options.length)?_c('button',{staticClass:"text-xs font-semibold text-blue-800 flex items-center gap-x-1 focus:outline-none add-option-btn",on:{"click":() => {
            _vm.$emit('update-modifier', {
              has_variant: true,
            });
            _vm.$emit('toggle-add-options');
          }}},[_c('img',{staticClass:"w-5 h-5",attrs:{"src":require("@/assets/svg/tiny-plus-bold.svg"),"alt":"tiny-plus-icon"}}),_c('span',{staticClass:"font-extrabold"},[_vm._v(" Add Option ")])]):_vm._e()],1),_c('div',{staticClass:"md:flex flex-row gap-x-10 mt-8 mb-5"},[_c('div',{staticClass:"flex-column md:w-4/12"},[_c('trac-input',{attrs:{"type":"number","placeholder":"Selling Price"},model:{value:(_vm.modifier.sale_price),callback:function ($$v) {_vm.$set(_vm.modifier, "sale_price", _vm._n($$v))},expression:"modifier.sale_price"}})],1),_c('div',{staticClass:"flex-column md:w-4/12"},[_c('trac-input',{attrs:{"type":"number","placeholder":"Cost Price"},model:{value:(_vm.modifier.cost_price),callback:function ($$v) {_vm.$set(_vm.modifier, "cost_price", _vm._n($$v))},expression:"modifier.cost_price"}})],1)]),_c('h2',{staticClass:"text-lg text-primaryBlue font-medium"},[_vm._v("SKU Details")]),_c('div',{staticClass:"md:flex flex-row gap-x-10 mt-8"},[_c('div',{staticClass:"flex-column md:w-4/12"},[_c('trac-input',{attrs:{"value":_vm.modifier.barcode,"placeholder":"SKU / Barcode"},on:{"input":function($event){return _vm.$emit('update-modifier', {
              barcode: $event,
            })}}})],1),_c('div',{staticClass:"md:w-2/12"},[_c('trac-button',{staticClass:"uppercase text-xs w-full",attrs:{"variant":"outline"},on:{"button-clicked":_vm.generateBarcode}},[_vm._v(" Generate SKU / Barcode ")])],1)]),(!_vm.modifier.id ? !_vm.modifier.has_variant : true)?_c('div',{staticClass:"mt-8 md:mr-32 pt-8 border-t font-semibold text-sm"},[_c('trac-toggle',{attrs:{"active":_vm.modifier.manage_stock,"disabled":_vm.modifier.id},on:{"newval":_vm.updateManageStock},model:{value:(_vm.modifier.manage_stock),callback:function ($$v) {_vm.$set(_vm.modifier, "manage_stock", $$v)},expression:"modifier.manage_stock"}},[_vm._v("Would you like to manage stock for this item")])],1):_vm._e(),(_vm.modifier.manage_stock)?_c('ManageStock',{attrs:{"stores":_vm.stores,"product":_vm.modifier},on:{"set-store-stock":function($event){return _vm.$emit('update-modifier', { store_stock: $event })}}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }